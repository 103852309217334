import { goto } from '$app/navigation';
import { PUBLIC_SERVER_URL } from '$env/static/public';
import { sentryCaptureException } from '$lib/components/component-functions';
import socketio from '@feathersjs/socketio-client';
import { createClient, type ServiceTypes } from 'fixee-server';
import { io } from 'socket.io-client';
import { inDevelopment } from '../env';
import routes from '../routes/routes';
import { isClientSide } from '../utils';

export const FIXEE_TOKEN_NAME = 'fixee-jwt';

function getNewClient(sockerUrl: string) {
	const socket = io(sockerUrl, {
		transports: ['websocket']
	});

	socket.on('connect', () => {
		// console.log('connect');
	});
	socket.on('disconnect', () => {
		// console.log('disconnect');
	});

	const connection = socketio(socket) as any;

	return createClient<ServiceTypes>(connection, {
		storageKey: FIXEE_TOKEN_NAME
	});
}

let fixeeClient = {} as ReturnType<typeof createClient>;

export function getFixeeJwt() {
	if (!isClientSide()) return;
	return window.localStorage.getItem(FIXEE_TOKEN_NAME);
}

export function initFixeeClient(sockerUrl: string) {
	fixeeClient = getNewClient(sockerUrl);
	fixeeClient.io?.on('disconnect', (reason: any) => {
		console.log('Disconnected from FIXEE server : ', reason);
	});
	fixeeClient.io?.on('connect', async () => {
		console.log('Connected to FIXEE server');
		if (!isClientSide()) return;
		// Important, do not await this function expecially if you don't have a fixee-jwt in your local storage.
		// or else the server will crash, not gud
		try {
			if (!getFixeeJwt()) return;

			await fixeeClient.reAuthenticate();
		} catch (error) {
			sentryCaptureException(error);
			console.log('fixeeClient onConnect to FIXEE server, error', error);
 
			// If we change the feathers secret, the signature will be invalid, so we need to reauthenticate through the login page
			if ((error as any).message.includes('invalid signature')) { 
				goto(routes.login, { replaceState: true });
			}
		}
	});
}

if (inDevelopment()) console.log('SERVER URL', PUBLIC_SERVER_URL);

// It is important to use the client like so. We have to initialize it on the front.
// If we don't we could miss the "authenticated" event when using "reAuthenticate", it would be baaaaad
const useFixeeClient = () => {
	if (Object.keys(fixeeClient).length === 0 && PUBLIC_SERVER_URL) {
		initFixeeClient(PUBLIC_SERVER_URL);
	}
	return fixeeClient;
};

export default useFixeeClient;
